<template>
  <div class="indemnity-p-box" ref="model-box">
    <div class="indemnity-p-box-pie">
      <div class="indemnity-p-box-pie-item">
        <header-name title="损失类型" />
        <Chart style="height: 100%" ref="pieBox" :option="lossPieOptions" />
      </div>
      <div class="indemnity-p-box-pie-item">
        <header-name title="出险类型" />
        <Chart style="height: 100%" ref="pieBox" :option="pieOptions" />
      </div>
    </div>
    <div class="indemnity-p-box-statistics" ref="progressBox">
      <div class="indemnity-p-box-statistics-item" v-for="item,index in proportionCharts" :key="index">
        <Chart
          :style="{ height: progressWidth + 'px', width: progressWidth + 'px' }"
          ref="pieBox"
          :option="item.barOptions"
        />
        <div>
          <div class="indemnity-p-box-statistics-item-text">{{ item.name || '--' }}</div>
          <div class="indemnity-p-box-statistics-item-num">{{ item.value || '--' }}</div>
        </div>
      </div>
      <!-- <div class="indemnity-p-box-statistics-item">
        <Chart
          :style="{ height: progressWidth + 'px', width: progressWidth + 'px' }"
          ref="pieBox"
          :option="linkOptions"
        />
        <div>
          <div class="indemnity-p-box-statistics-item-text">行驶案件</div>
          <div class="indemnity-p-box-statistics-item-num">33333</div>
        </div>
      </div>
      <div class="indemnity-p-box-statistics-item">
        <Chart
          :style="{ height: progressWidth + 'px', width: progressWidth + 'px' }"
          ref="pieBox"
          :option="linkOptions"
        />
        <div>
          <div class="indemnity-p-box-statistics-item-text">作业案件</div>
          <div class="indemnity-p-box-statistics-item-num">33333</div>
        </div>
      </div> -->
    </div>
    <!-- <Chart style="height: 100%" ref="lineBox" :option="lineOptions" /> -->
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import * as echarts from "echarts";
import HeaderName from "@/views/BigScreenDisplay/components/HeaderName";
export default {
  components: { Chart, HeaderName },
  data() {
    return {
      pieOptions: {},
      progressWidth: 0,
      lossPieOptions: {},
      proportionCharts: []
    };
  },
  props: {
    options: {
      type: Object,
      default: ()=> {}
    }
  },
  watch: {
    options: {
      handler(v) {
        this.$nextTick(()=>{
          // this.initChart(v.compensateLegalCaseNumber || []);
          this.proportionCharts = v.caseClassificationStatistics.map(val=> {
            return {
              ...val,
              barOptions: {}
            }
          });
          this.$nextTick(val=>{
            this.proportionCharts.map((val,ind)=> {
              this.proportionCharts[ind].barOptions = this.setlinkOptions(this.proportionCharts[ind].ratio)
            })
          })
          this.lossPieOptions = this.setPieOptions(v.lossType);
          this.pieOptions = this.setPieOptions(v.outRiskType);
          this.$forceUpdate();
        })
      },
      deep: true,
      immediate: false
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // getOilAndTimeTrend().then(res=>{
      //    // this.quantityNum = interval(res.obj.totalUser || 0);
      this.$nextTick(() => {
        const clientWidth = this.$refs["progressBox"].offsetWidth * 0.18;
        const clientHeight = this.$refs["progressBox"].offsetHeight * 0.8;
        this.progressWidth =
          clientWidth > clientHeight ? clientHeight : clientWidth;
        // this.initChats();
      });
      // })
    },
    setlinkOptions(value = 0) {
      const sizeHNum = this.getSize(0.3, 2);
      return {
        title: {
          text: (value || "-") + "%",
          x: "center",
          y: "center",
          textStyle: {
            color: "#fff",
            fontSize: sizeHNum * 1.6,
            fontWeight: "600",
          },
        },
        angleAxis: {
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          min: 0,
          max: 100,
          startAngle: 90,
        },
        radiusAxis: {
          type: "category",
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
          },
          data: [],
        },
        polar: {
          radius: "150%",
          center: ["50%", "50%"],
        },
        series: [
          // 极坐标系下柱状图
          // 实际值
          {
            type: "bar",
            data: [value],
            z: 1,
            center: ["94%", "75%"],
            coordinateSystem: "polar",
            barMaxWidth: sizeHNum *0.6,
            roundCap: 1,
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              {
                offset: 0,
                color: "rgba(255, 109, 38, 1)",
              },
              {
                offset: 1,
                color: "rgba(253, 203, 90, 1)",
              },
            ]),
          },
          // 背景图形
          {
            type: "bar",
            data: [100],
            z: 0,
            silent: true,
            coordinateSystem: "polar",
            barMaxWidth: sizeHNum *0.6,
            roundCap: true,
            color: "#cee7f9",
            barGap: "-100%",
            itemStyle: {
              normal: {
                color: "transparent",
                borderColor: "rgba(1, 13, 51, .6)",
                borderWidth: sizeHNum * 0.6,
              },
            },
          },
        ],
      };
    },
    setPieOptions(data = []) {
      const sizeHNum = this.getSize(0.3, 2);
      return {
        tooltip: {
          show: true,
          confine: true,
          backgroundColor: "rgba(9,103,138,0.8)",
          textStyle: {
            color: "rgba(255,255,255,1)",
          },
          formatter: function (e) {
            return e.name + "：" + e.data.ratio + "%";
          },
          trigger: "item",
          position: "top",
          borderColor: "transparent",
          extraCssText: "0px 0px 8px 0px rgba(0,0,0,0.2);border-radius: 4px;",
        },
        legend: {
          show: false,
        },
        color: [
          "RGBA(4, 186, 207, 1)",
          "RGBA(92, 216, 166, 1)",
          "RGBA(91, 144, 250, 1)",
          "RGBA(110, 200, 235, 1)",
          "RGBA(93, 112, 145, 1)",
          "RGBA(146, 111, 201, 1)",
          "RGBA(232, 105, 73, 1)",
          "RGBA(255, 157, 76, 1)",
          "RGBA(246, 189, 22, 1)",
          "RGBA(255, 152, 197, 1)",
        ],
        grid: {
          top: "6%",
          left: 0,
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: "70%",
            // labelLine: {
            //   show: true,
            //   length: 1,
            //   length2: 5,
            //     color: "#ffffff",
            //     borderType: 'dashed'
            // },
            label: {
              show: true,
              color: "#96A2B5",
              // lineHeight: 8,
              minMargin: sizeHNum * 1,
              formatter: function (e) {
                return "{name|" + e.name + "}\n{value|" + e.value + '/' + e.percent + "%}";
              },
              rich: {
                name: {
                  fontSize: sizeHNum * 1.4,
                  color: "#ffffff",
                  padding: [0, 0, 0, 0],
                },
                value: {
                  fontSize: sizeHNum * 1.4,
                  color: "#FFFD37",
                  padding: [sizeHNum * 0.2, 0, 0, 0],
                },
                percent: {
                  fontSize: sizeHNum * 1.6,
                  color: "rgba(255, 255, 255, 0.6)",
                },
              },
            },
            showEmptyCircle: true,
            labelLine: {
              length: sizeHNum * 1,
              length2: sizeHNum * 2.5,
              lineStyle: {
                color: "#ffffff",
                type: "dashed",
              },
            },
            emptyCircleStyle: {
              color: "#ffffff",
            },
            data:
              data || [],
          },
        ],
      };
    },
    getSize(res, type) {
      const clientWidth = this.$refs["model-box"].offsetWidth;
      const clientHeight = this.$refs["model-box"].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
    initChats(dataList) {
      this.setPieOptions();
      this.setlinkOptions();
    },
  },
};
</script>

<style lang="scss" scoped>
.indemnity-p-box {
  padding: 2%;
  height: 88%;
  &-pie {
    display: flex;
    height: 66%;
    padding: 2%;
    &-item {
      width: 49%;
    }
  }
  &-statistics {
    display: flex;
    height: 33%;
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 33%;
      &-text {
        font-size: 0.12rem;
        color: #fff;
      }
      &-num {
        font-size: 0.24rem;
        color: #fff;
      }
    }
  }
}
</style>