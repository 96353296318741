<template>
  <div class="scheme-box">
    <div class="scheme-box-item scheme-box-item-head">
      <div class="scheme-box-item-flex">产品方案</div>
      <div class="scheme-box-item-flex">保险公司</div>
      <div class="scheme-box-item-right">综合保费</div>
    </div>
    <div class="scheme-box-content" ref="model-box">
      <vue-seamless-scroll :data="options"
        :style="{ width: '100%',height: boxH+'px', overflow: 'hidden' }"
        :class-option="defaultOption">
        <div class="scheme-box-item" v-for="item,index in options" :key="index">
          <div class="scheme-box-item-flex">{{ item.productSchemeName || '--' }}</div>
          <div class="scheme-box-item-flex">{{ item.insuranceCompany || '--' }}</div>
          <div class="scheme-box-item-right">{{ item.comprehensivePremium || '--' }}</div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll';
export default {
  components: {
    vueSeamlessScroll
  },
  props: {
    options: {
      type: Array,
      default: ()=> []
    }
  },
  data() {
    return {
      situationoptions: [{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      },],
      defaultOption: {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      },
      boxH: 0,
    };
  },
  created() {
    //  this.init();
    this.$nextTick(() => {
      this.boxH = this.$refs["model-box"].offsetHeight;
    });
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.scheme-box {
  padding: 1%;
  height: 82%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  &-content {
    flex: 1;
  }
  &-item {
    display: flex;
    height: 0.33rem;
    line-height: 0.33rem;
    font-size: 0.14rem;
    color: #9EC7FF;
    margin: 0 0.15rem;
    padding-left: 0.12rem;
    &-flex {
      flex: 1;
    }
    &-right {
      width: 1.4rem;
      text-align: center;
    }
    &-head {
      height: 0.4rem;
      line-height: 0.4rem;
      font-weight: 500;
      font-size: 0.17rem;
      color: #20CEFA;
    }
  }
}
.scheme-box-item:nth-child(even) {
    background: rgba(18,39,153,0.3);
}
</style>