<template>
  <div class="proportion-box">
    <div class="proportion-box-item" v-for="(item,index) in options" :key="index">
      <div class="proportion-box-item-headline">
        <div class="proportion-box-item-headline-left" :class="{ 'proportion-box-item-headline-left1': index == 0, 'proportion-box-item-headline-left2': index == 1 }">{{index + 1}}.{{ item.insuranceTypeName || '--' }}</div>
        <div class="proportion-box-item-headline-right">{{ item.insuranceAmount || '--' }}万</div>
      </div>
      <el-progress :text-inside="true" :percentage="item.insuranceTypeThan || 0"></el-progress>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: ()=> []
    }
  },
  data() {
    return {
      proportionList: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},]
    }
  }
}
</script>

<style lang="scss" scoped>
.proportion-box {
  padding: 2%;
  height: 88%;
  overflow: auto;
  &-item {
    margin-bottom: 0.08rem;
    &-headline {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.15rem;
      &-left {
        font-size: 0.14rem;
        color: #eee;
        padding: 0.06rem 0.08rem;
        background: linear-gradient(90deg, #196BBB, rgba(2, 8, 28, 0));
        border-radius: 10px 0 0 10px;
      }
      &-left1 {
        background: linear-gradient(90deg, #A92C2C, rgba(2, 8, 28, 0));
      }
      &-left2 {
        background: linear-gradient(90deg, #A95D2C, rgba(2, 8, 28, 0));
      }
      &-right {
        font-size: 0.16rem;
        color: #96F5F8;
      }
    }
  }
  ::v-deep .el-progress {
    margin: 0 0.2rem;
    .el-progress-bar__outer {
      overflow: initial;
      background-color: #0D2355 !important;
    }
    .el-progress-bar__innerText {
      top: 50% !important;
      right: 0 !important;
      transform: translate(140%, -50%);
    }
  }
}
</style>