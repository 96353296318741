<template>
  <div class="scheme-box" ref="model-box">
      <Chart style="height:100%;min-height: 100px"
        ref="chartBox"
        :option="lineOptions"></Chart>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
import * as echarts from 'echarts';
export default {
  components: {
    Chart
  },
  props: {
    options: {
      type: Array,
      default: ()=> []
    }
  },
  watch: {
    options: {
      handler(v) {
        this.$nextTick(()=>{
          this.initChart(v || []);
        })
      },
      deep: true,
      immediate: false
    }
  },
  data() {
    return {
      lineOptions: {},
    };
  },
  created() {
    //  this.init();
    this.$nextTick(() => {
      // this.initChart();
    });
  },
  methods: {
    initChart(datalist) {
      const sizeHNum = this.getSize(0.3, 2);
      let cities = datalist.map(val=> val.companyName)
      // [
      //   "1月",
      //   "2月",
      //   "3月",
      //   "4月",
      //   "5月",
      //   "6月",
      //   "7月",
      //   "8月",
      //   "9月",
      //   "10月",
      //   "11月",
      //   "12月",
      // ];
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            color: "#fff",
          },
          position: function (point, params, dom, rect, size) {
                const { viewSize, contentSize } = size;
                const x = point[1]
                const y = point[0]
                const obj = {}
                if (( x - contentSize[1] ) > 0) {
                  obj.top = x - contentSize[1]
                } else if ((viewSize[1] - x) > contentSize[1]) {
                  obj.top = x
                }else {
                  obj.top = 0
                }
                if ((y - contentSize[0]) > 0) {
                  obj.left = y - contentSize[0]
                } else if ((viewSize[0] - y) > contentSize[0]) {
                  obj.left = y
                }else {
                  obj.left = 0
                }
                // 固定在顶部
                return obj;
          }
        },
        grid: {
          borderWidth: 0,
          top: 36,
          bottom: "16%",
          left: "13%",
          right: "16%",
          color: "#fff",
        },
        legend: {
          top: "1%",
          right: "3%",
          data: ["数量", "金额"],
          icon: "pin",
          textStyle: {
            color: "#ffffff",
              fontSize: sizeHNum * 2,
          },
        },

        calculable: true,
        xAxis: [
          {
            type: "category",
            name: '保险公司',
            nameTextStyle: {
              fontSize: sizeHNum * 2,
              fontWeight: 300,
              color: "#fff"
            },
            axisLine: {
              lineStyle: {
                color: "rgba(9, 54, 109, 1)",
              },
            },
            axisTick: {
              //刻度线
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
              fontSize: sizeHNum * 2,
                fontWeight: 300,
                color: "#fff"
              },
            },
            axisLabel: {
              color: "#ffffff",
              fontSize: sizeHNum * 2,
            width: sizeHNum * 11,
            overflow: 'truncate',
              formatter: function (value) {
                let valueArr = [];
                if (value.length > 4) {
                  let str1 = value.slice(0, 5);
                  let str2 = value.slice(5);
                  valueArr = [str1, str2];
                } else {
                  valueArr = [value];
                }
                return valueArr.join("");
              },
            },
            data: cities,
          },
        ],
        yAxis: [
          {
            type: "value",
            name: '保单数量(张)',
            position: "left",
            axisLine: {
              lineStyle: {
                color: "#575E71",
              },
            },
          nameTextStyle: {
              fontSize: sizeHNum * 2,
              fontWeight: 300,
              color: "#fff"
          },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "rgba(13, 151, 235, .2)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              fontSize: sizeHNum * 2,
              fontWeight: 300,
              color: "#fff"
            },
            splitArea: {
              show: false,
            },
            // max: max_left,
            // interval: interval_left,
            splitNumber: 5,
          },
          {
            type: "value",
            name: '保费金额(万元)',
            axisLine: {
              lineStyle: {
                color: "#575E71",
              },
            },
          nameTextStyle: {
              fontSize: sizeHNum * 2,
              fontWeight: 300,
              color: "#fff"
          },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "rgba(13, 151, 235, .2)",
              },
            },
            position: "right",
            axisLabel: {
              formatter: "{value}",
              fontSize: sizeHNum * 2,
              fontWeight: 300,
              color: "#fff"
            },
            splitNumber: 5,
          },
        ],
        series: [
          {
            type: "bar",
            name: '主承保数',
            barMaxWidth: sizeHNum * 2,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                { offset: 1, color: "rgba(155, 248, 55, 1)" },
                { offset: 0, color: "rgba(13, 224, 112, 0.1)" },
              ]),
              borderRadius: [6, 6, 0, 0],
              label: {
                show: false,
                position: "inside",
                formatter: function (p) {
                  return p.value > 0 ? p.value : "";
                },
              },
            },
            data: datalist.map(val=> val.coinsuranceNumber),
          },
          {
            type: "bar",
            name: '共保数',
            barMaxWidth: sizeHNum * 2,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                { offset: 1, color: "rgba(0, 180, 255, 0.98)" },
                { offset: 0, color: "rgba(0, 121, 255, 0.1)" },
              ]),
              borderRadius: [6, 6, 0, 0],
              label: {
                show: false,
                position: "inside",
                formatter: function (p) {
                  return p.value > 0 ? p.value : "";
                },
              },
            },
            data: datalist.map(val=> val.principalCover),
          },
          {
            name: '主承保金额',
            yAxisIndex: 1,
            type: "line",
            lineStyle: {
              width: sizeHNum * 0.2
            },
            symbolSize: sizeHNum * 1,
            itemStyle: {
              color: "rgba(254, 203, 90, 1)",
              borderRadius: 0,
              label: {
                show: false,
                position: "top",
                formatter: function (p) {
                  return p.value > 0 ? p.value + "%" : "";
                },
              },
            },
            data: datalist.map(val=> val.principalAmountCovered),
          },
          {
            name: '共保金额',
            yAxisIndex: 1,
            type: "line",
            symbolSize: sizeHNum * 1,
            lineStyle: {
              width: sizeHNum * 0.2
            },
            itemStyle: {
              color: "rgba(150, 245, 248, 1)",
              borderRadius: 0,
              label: {
                show: false,
                position: "top",
                formatter: function (p) {
                  return p.value > 0 ? p.value + "%" : "";
                },
              },
            },
            data: datalist.map(val=> val.coinsuranceAmount),
          },
        ],
      };
    },
    getSize(res, type) {
      const clientWidth = this.$refs["model-box"].offsetWidth;
      const clientHeight = this.$refs["model-box"].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
  }
}
</script>

<style lang="scss" scoped>
.scheme-box {
  padding: 1%;
  height: 82%;
}
</style>