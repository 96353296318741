<template>
  <div class="scheme-box" ref="model-box">
      <div
        class="situation-box"
        v-for="(item, index) in options"
        :key="index"
      >
        <div class="situation-box-left">
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.caseDescription"
            placement="top"
          > <div class="situation-box-left">{{ item.caseDescription }}</div>
          </el-tooltip></div>
        <div class="situation-box-progress">
          <el-tooltip
            class="item"
            effect="dark"
            :content="'预计赔付：' + item.caseAmount + '万'"
            placement="top"
          >
            <div
              class="situation-box-progress-scale"
              :style="{ width: (item.caseAmountRatio || 0) + '%' }"
            ></div>
          </el-tooltip>
        </div>
        <div class="situation-box-right">
          <span>{{ item.caseNumber }}</span
          >件
        </div>
      </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    options: {
      type: Array,
      default: ()=> []
    }
  },
  data() {
    return {
      situationoptions: [{
        caseRate:20,
        estimateAmount: 20,
        label: '测试',
        value: 20
      }]
    };
  },
  created() {
    this.$nextTick(() => {
    });
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.scheme-box {
  padding: 1%;
  height: 82%;
  
  .situation {
    position: relative;
    height: 55%;
    overflow: auto;
    padding: 8% 3% 3%;
    &-box {
      display: flex;
      align-items: center;
      color: #fff;
      margin-top: 1.6%;
      &-left {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 1.6rem;
        font-size: 0.14rem;
        margin-left: 0.14rem;
      }
      &-progress {
        flex: 1;
        height: 0.18rem;
        background-color: rgba(2, 102, 202, 0.1);
        margin: 0 4px;
        border-radius: 0.09rem;
        overflow: hidden;
        &-scale {
          height: 0.18rem;
          background: linear-gradient(90deg, #ff9d1d 0%, #fffd37 100%);
          border-radius: 0.09rem;
        }
      }
      &-right {
        width: 0.88rem;
        font-size: 0.1rem;
        text-align: center;
        span {
          font-family: "ZhanKuQingKeHuangYouTi";
          margin-right: 2px;
          font-size: 0.16rem;
        }
      }
    }
  }
}
</style>