import { render, staticRenderFns } from "./zeroIndemnity.vue?vue&type=template&id=3b80215f&scoped=true"
import script from "./zeroIndemnity.vue?vue&type=script&lang=js"
export * from "./zeroIndemnity.vue?vue&type=script&lang=js"
import style0 from "./zeroIndemnity.vue?vue&type=style&index=0&id=3b80215f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b80215f",
  null
  
)

export default component.exports