<template>
  <div class="service-box" ref="model-box">
    <!-- 统计 -->
    <div class="service-statistics"
      ref="service-box">
      <div class="service-statistics-view" :style="{ width: boxWidth +'px' }">
        <img src="../../../../assets/images/bigScreen/products/indemnityIcon.png"
          alt=""
          srcset="">
          <!-- :style="{ width: hostWidth +'px' }" -->
        <div class="service-statistics-view-float">
          <div class="service-statistics-view-num">{{ options.maturityLossRatio || 0 }}<span>%</span></div>
          <div class="service-statistics-view-name">满期赔付率</div>
        </div>
      </div>
      <div class="service-statistics-view" :style="{ width: boxWidth +'px' }">
        <img src="../../../../assets/images/bigScreen/products/indemnityIcon.png"
          alt=""
          srcset="">
        <div class="service-statistics-view-float">
          <div class="service-statistics-view-num">{{ options.reportedLossRatio || 0 }}<span>%</span></div>
          <div class="service-statistics-view-name">已报告赔付率</div>
        </div>
      </div>
      <div class="service-statistics-view" :style="{ width: boxWidth +'px' }">
        <img src="../../../../assets/images/bigScreen/products/indemnityIcon.png"
          alt=""
          srcset="">
        <div class="service-statistics-view-float">
          <div class="service-statistics-view-num">{{ options.caseload || 0 }}</div>
          <div class="service-statistics-view-name">案件数量(件)</div>
        </div>
      </div>
      <div class="service-statistics-view" :style="{ width: boxWidth +'px' }">
        <img src="../../../../assets/images/bigScreen/products/indemnityIcon.png"
          alt=""
          srcset="">
        <div class="service-statistics-view-float">
          <div class="service-statistics-view-num">{{ options.amountPaid || 0 }}</div>
          <div class="service-statistics-view-name">赔付金额(万元)</div>
        </div>
      </div>
    </div>

    <!-- 历史赔付率 -->
    <header-name title="赔付案件量分布趋势" />
    <div class="service-bar"
      ref="service-bar">
      <Chart style="height:100%;"
        ref="chartBox"
        :option="lineOptions"></Chart>
    </div>
  </div>
</template>

<script>
import HeaderName from '@/views/BigScreenDisplay/components/HeaderName';
import Chart from "@/components/Chart/Charts";
import * as echarts from 'echarts';
import { getServiceOnlineView } from "@/api/BigScreenDisplay";
export default {
  components: {
    HeaderName,
    Chart
  },
  props: {
    options: {
      type: Object,
      default: ()=> {}
    }
  },
  watch: {
    options: {
      handler(v) {
        this.$nextTick(()=>{
          this.initChart(v.compensateLegalCaseNumber || []);
        })
      },
      deep: true,
      immediate: false
    }
  },
  data() {
    return {
      lineOptions: {},
      hostWidth: 0,
      boxWidth: 0,
      serviceData: {}
    };
  },
  created() {
    //  this.init();
    this.$nextTick(() => {
      const clientWidth = this.$refs['service-box'].offsetWidth * 0.14;
      const clientHeight = this.$refs['service-box'].offsetHeight * 0.6;
      this.boxWidth = this.$refs['service-box'].offsetWidth * 0.24;
      this.hostWidth = clientWidth > clientHeight ? clientHeight : clientWidth;
      // this.initChart();
    });
  },
  methods: {
    init() {
      getServiceOnlineView().then(res => {
        this.serviceData = res.data;
        this.$emit('changeClaimService', Number(res.data.serviceCaseNum || 0))
        this.initChart(res.data.historicalLossRate);
      });
    },
    initChart(datalist) {
      const sizeHNum = this.getSize(0.3, 2);
      let cities = datalist.map(val=> val.month)
      // [
      //   "1月",
      //   "2月",
      //   "3月",
      //   "4月",
      //   "5月",
      //   "6月",
      //   "7月",
      //   "8月",
      //   "9月",
      //   "10月",
      //   "11月",
      //   "12月",
      // ];
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            color: "#fff",
          },
        },
        grid: {
          borderWidth: 0,
          top: "18%",
          bottom: "14%",
          left: "20%",
          right: "20%",
          color: "#fff",
        },
        legend: {
          top: "1%",
          right: "3%",
          data: ["数量", "金额"],
          icon: "pin",
          textStyle: {
            color: "#ffffff",
            fontSize: sizeHNum * 1.2,
          },
        },

        calculable: true,
        xAxis: [
          {
            type: "category",
            axisLine: {
              lineStyle: {
              color: "rgba(9, 54, 109, 1)"
              },
            },
            axisTick: {
              //刻度线
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: 12,
                fontWeight: 300,
                color: "#fff"
              },
            },
            axisLabel: {
              color: "#ffffff",
              formatter: function (value) {
                let valueArr = [];
                if (value.length > 4) {
                  let str1 = value.slice(0, 5);
                  let str2 = value.slice(5);
                  valueArr = [str1, str2];
                } else {
                  valueArr = [value];
                }

                return valueArr.join("");
              },
            },
            data: cities,
          },
        ],
        yAxis: [
          {
            type: "value",
            position: "left",
            axisLine: {
              lineStyle: {
                color: "#575E71",
              },
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "rgba(13, 151, 235, .2)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              interval: 0,
              fontSize: 12,
              fontWeight: 300,
              color: "#fff"
            },
            splitArea: {
              show: false,
            },
            // max: max_left,
            // interval: interval_left,
            splitNumber: 5,
          },
          {
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#575E71",
              },
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "rgba(13, 151, 235, .2)",
              },
            },
            position: "right",
            axisLabel: {
              formatter: "{value}",
              fontSize: 12,
              fontWeight: 300,
              color: "#fff"
            },
            splitNumber: 5,
          },
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            barMaxWidth: sizeHNum * 2,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                { offset: 1, color: "#1BDFFC" },
                { offset: 0, color: "#1251D2" },
              ]),
              borderRadius: [6, 6, 0, 0],
              label: {
                show: false,
                position: "inside",
                formatter: function (p) {
                  return p.value > 0 ? p.value : "";
                },
              },
            },
            data: datalist.map(val=> val.amount),
          },
          {
            name: "数量",
            yAxisIndex: 1,
            type: "line",
            symbol: "none",
            itemStyle: {
              color: "#FFFD37",
              borderRadius: 0,
              label: {
                show: false,
                position: "top",
                // formatter: function (p) {
                //   return p.value > 0 ? p.value + "%" : "";
                // },
              },
            },
            data: datalist.map(val=> val.number),
          },
        ],
      };
    },
    getSize(res, type) {
      const clientWidth = this.$refs["model-box"].offsetWidth;
      const clientHeight = this.$refs["model-box"].offsetHeight;
      if (!clientWidth) return;
      const fontSize = 100 * (clientWidth / 1920);
      const fontSize2 = 100 * (clientHeight / 1080);
      return res * (type == 2 ? fontSize2 : fontSize);
    },
  }
}
</script>

<style lang="scss" scoped>
.service-box {
  display: flex;
  flex-direction: column;
  height: 91.8%;
  padding: 0 3%;
  .service-bar {
    height: 71.5%;
    margin-bottom: 1%;
  }
  .service-statistics {
    height: 22%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1%;
    &-view {
      width: 24%;
      height: 100%;
      text-align: center;
      position: relative;
      &-name {
        text-align: center;
        width: 100%;
        font-size: 0.15rem;
        color: #fff;
        margin-top: 0.1rem;
      }
      &-num {
        text-align: center;
        font-family: 'ZhanKuQingKeHuangYouTi';
        width: 100%;
        font-size: 0.26rem;
        color: #FFFD37;
        margin-top: 0.1rem;
        span {
          font-size: 0.12rem;
        color: #FFFD37;
        }
      }
      &-float {
        position: absolute;
        bottom: 50%;
        left: 0;
        right: 0;
        transform: translateY(14%);
      }
      img {
        // width: 0.8rem;
        height: 80%;
        display: block;
        margin: 1.5% auto 3.5%;
      }
    }
  }
}

@media only screen and (max-height: 700px) {
  .service-statistics {
    margin-top: 0% !important;
  }
}
</style>