<template>
  <div class="scale-box">
    <div class="scale-box-statistics">
      <div class="scale-box-statistics-item">
        <img src="@/assets/images/bigScreen/products/policyNumIcon.png" class="scale-box-statistics-item-icon" alt="" srcset="">
        <div class="scale-box-statistics-item-right">
          <div class="scale-box-statistics-item-right-text">保费金额</div>
          <div class="scale-box-statistics-item-right-num2">{{ options.premiumAmount || '--' }}</div>
        </div>
      </div>
      <div class="scale-box-statistics-item">
        <img src="@/assets/images/bigScreen/products/equipmentNumIcon.png" class="scale-box-statistics-item-icon" alt="" srcset="">
        <div class="scale-box-statistics-item-right">
          <div class="scale-box-statistics-item-right-text">设备数量</div>
          <div class="scale-box-statistics-item-right-num">{{ options.deviceNumber || '--' }}</div>
        </div>
      </div>
    </div>
    <div class="scale-box-charts">
      <Chart style="height: 100%" ref="lineBox" :option="lineOptions" />
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Chart/Charts";
export default {
  components: { Chart },
  data() {
    return {
      lineOptions: {}
    }
  },
  props: {
    options: {
      type: Object,
      default: ()=> {}
    }
  },
  watch: {
    options: {
      handler(v) {
        this.$nextTick(()=>{
          // this.initChart(v.compensateLegalCaseNumber || []);
          this.initChats(v.premiumStatistics)
        })
      },
      deep: true,
      immediate: false
    }
  },
  created() {
    // this.init()
  },
  methods: {
   init() {
      // getOilAndTimeTrend().then(res=>{
      //    // this.quantityNum = interval(res.obj.totalUser || 0);
         this.$nextTick(() => {
            this.initChats();
         });
      // })
   },
    initChats(dataList) {
      let xAxisData = dataList.map(val=> val.month);
      const series = [
        {
         name: '保费',
          "data": dataList.map(val=> val.premium),
          "type": "line",
          "smooth": true,
          "symbol": "none",
          "color": {
            "x": 0,
            "y": 0,
            "x2": 0,
            "y2": 1,
            "type": "linear",
            "global": false,
            "colorStops": [
              {
                "offset": 0,
                "color": "rgba(150, 245, 248, 1)"
              }
            ]
          },
          areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [ // 渐变颜色
                  {
                    offset: 0,
                    color: 'rgba(0, 190, 189,0.50)'
                  },
                  {
                    offset: 1,
                    color: 'rgba(0, 190, 189,0.00)'
                  }
                ],
                global: false
              }
            },
          yAxisIndex :0,
        },
      ];
      // 数图配置项
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
         //  formatter: function (params, ticket, callback) {
         //    // console.log(params)
         //    let str = `${ params[0].axisValue }</br>`;
         //    params.forEach((item, idx) => {
         //      str += idx === params.length - 1 ? "" : "<br/>";
         //    });
         //    return str;
         //  }
        },
        grid: {
          top: "20%", // 等价于 y: '16%'
          left: "3%",
          right: "40",
          bottom: "5%",
          containLabel: true
        },
        legend: {
          itemGap: 100,
          data: [
            'Upload',
            'Download'
          ],
          textStyle: {
            //图例文字的样式
            color: "#fff"
          },
          left: "center",
          top: 10
        },
        xAxis: {
          type: "category",
          name: '月份',
          boundaryGap: false,
          data: xAxisData,
          axisLine: {
            lineStyle: {
              color: "rgba(9, 54, 109, 1)"
            }
          },
          nameTextStyle: {
              fontSize: 12,
              fontWeight: 300,
              color: "#fff"
          },
           axisTick: {
            length: 0,
            lineStyle: {
              color: 'red'
            }
           },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 12,
              fontWeight: 300,
              color: "#fff"
            }
          }
        },

        yAxis: [{
          type: "value",
          name: '保费(万元)',
          position: 'left',
          axisLine: {
            lineStyle: {
              fontSize: 12,
              fontWeight: 300,
              color: "#fff"
            }
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(13, 151, 235, .3)"
            }
          },
          axisLabel: {
            // formatter: function (value) {
            //   return value +'H';
            // },
            textStyle: {
              fontSize: 12,
              fontWeight: 300,
              color: "#fff"
            }
          }
        },],
        series: series
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.scale-box {
  padding: 2%;
  height: 88%;
  &-statistics {
    height: 33%;
    max-width: 100%;
    display: flex;
    align-items: center;
    &-item {
      height: 100%;
      max-width: 50%;
      width: 50%;
      display: flex;
      align-items: center;
      &-icon {
        height: 80%;
      }
      &-right {
        height: 50%;
        margin-top: 6%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        &-text {
          font-weight: 500;
          font-size: 0.16rem;
          color: #FFFFFF;
        }
        &-num {
          font-family: 'ZhanKuQingKeHuangYouTi';
          font-size: 0.2rem;
          color: #90FFAD;
        }
        &-num2 {
          font-family: 'ZhanKuQingKeHuangYouTi';
          font-size: 0.2rem;
          color: #479FFE;
        }
      }
    }
  }
  &-charts {
    height: 67%;
  }
}
</style>